import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ locale, type }) => (
  <StaticQuery
    query={graphql`
      query  AllStrapiPessoa {
        allPessoasJson: allStrapiPessoa (sort: {fields: order, order: ASC}){
          edges {
            node {
              name
              title
              type
              locale
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 220, maxHeight: 220) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const pessoas = data.allPessoasJson.edges

      return (
        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12">
            <div className="row justify-content-md-start">
              {pessoas
                .filter(n => n.node.locale === locale)
                .filter(function (n) {
                  return n.node.type === type
                })
                .map((n, i) => {
                  return (
                    <div
                      className="col-sm-4 col-xs-12"
                      key={i}
                      style={{ marginBottom: 60 }}>
                      {n.node.image ? (
                        <Img
                          style={{ width: 220, height: 220 }}
                          fluid={n.node.image.localFile.childImageSharp.fluid}
                        />
                      ) : (
                          <div
                            className="dummy-foto"
                            style={{ width: 220, height: 220 }}
                          />
                        )}
                      <div
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          height: 25,
                          overflow: "hidden",
                        }}
                        className="text-default bold">
                        {n.node.name}
                      </div>
                      <div className="text-default">{n.node.title}</div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      )
    }}
  />
)
