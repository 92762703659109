import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Banner from "../components/Banner/banner2"
import PeopleBlock from "../components/People/peopleBlock"
import { withPrefix } from "gatsby"


export const queryPeople = graphql`
  query Pessoas($locale: String!) {
    childPeopleJson: strapiPeople(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
              ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      researchersTitle
      supportTitle
    }
  }
`;

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.childPeopleJson

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={true}
        opacityValue={0.34}
      //logo={obcrei}
      />
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h2>{content.researchersTitle}</h2>
          </div>
        </div>
        <PeopleBlock locale={locale} type={"investigador"} />
        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h2>{content.supportTitle}</h2>
          </div>
        </div>
        <PeopleBlock locale={locale} type={"suporte"} />
      </div>
    </Layout>
  )
}

// export const pessoas = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/people" }
//     ) {
//       childPeopleJson {
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         bannerText
//         researchersTitle
//         supportTitle
//       }
//     }
//   }
// `
