import React from 'react';

import Img from 'gatsby-image';

import './banner2.scss';

const Banner2 = ({banner, text, logo, layer, opacityValue}) => {
  var style = null;

  if (opacityValue) {
    style = {opacity: opacityValue};
  } else {
    return null;
  }

  return (
    <div
      className="banner-wrapper d-flex align-items-center flex-column"
      style={{height: 294}}
    >
      <div className="container-fluid">
        <Img fluid={banner.childImageSharp.fluid} />
        {layer ? <div className="banner-layer" style={style} /> : null}
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          {logo
            ? <div
                className="col col-sm-10 col-xs-12"
                style={{marginBottom: '18px'}}
              >
                <img src={logo} />
              </div>
            : null}
          <h1
            className="col col-sm-10 col-xs-12"
            dangerouslySetInnerHTML={{__html: text}}
            style={{color: '#FFFFFF'}}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner2;
